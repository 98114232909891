@import 'src/assets/styles/functions';
@import 'src/assets/styles/variables';

.card {
  max-width: 275px;
  width: 100%;
  border-radius: 6px;
  padding: calculateRem(16px) calculateRem(30px);
  background-color: $backgound_color_gray;
}

.wrapper {
  position: relative;
  width: 100%;
}

.title {
  font-weight: 600;
  font-size: calculateRem(20px);
  line-height: calculateRem(24px);
  text-transform: uppercase;
  color: $color_secondary;
}

.value {
  font-weight: 800;
  font-size: calculateRem(48px);
  line-height: calculateRem(59px);
  color: $color_primary;
}
