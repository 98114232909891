.wrapper {
  max-width: 500px;
}

.label {
  min-width: 160px;
  align-self: center;
}

.field {
  min-width: 330px !important;
}

.inputAmountCurrencySing {
  font-size: 18px;
  line-height: 0;
}
