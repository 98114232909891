@import 'src/assets/styles/variables';

.bar {
  margin-bottom: 2.5rem;
  span {
    text-transform: none !important;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  .active {
    color: $color_secondary !important;
  }
}
