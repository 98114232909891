@import "assets/styles/functions";
@import "assets/styles/variables";

.paper {
  margin: calculateRem(40px) !important;
  position: relative !important;
  overflow-y: visible !important;
  padding: calculateRem(40px);
  background: $backgound_color_gray !important;
  border-radius: calculateRem(16px) !important;
}

.modalCloseButton {
  position: absolute;
  right: calculateRem(5px);
  top: calculateRem(5px);
  cursor: pointer;
}
