@import 'src/assets/styles/functions';
@import 'src/assets/styles/variables';

.fieldWrapper {
  margin-bottom: calculateRem(8px);

  >div {
    margin-bottom: calculateRem(20px);
  }
}

.forgotPasswordWrapper {
  display: flex;
  justify-content: flex-end;
}

.forgotPassword {
  color: $color_error !important;
  &:hover{
    color: $color_error_secondary !important;
  }
}
