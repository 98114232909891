@import 'assets/styles/variables.scss';

.wrapper {
  box-shadow: none;
  width: 100%;

  & :global {
    & div > .MuiTable-root:first-child {
      border-radius: 1.5rem 1.5rem 0 0;
      background-color: $backgound_color_gray;
    }
    .MuiTableHead-root {
      background-color: $backgound_color_gray;
      .MuiTableCell-head {
        background-color: transparent;
        padding: 1.875rem .875rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
        text-transform: uppercase;
        color: $light_text_color_gray;
        &:first-child {
          border-radius: 1.5rem 0 0 0;
        }
        &:last-child {
          border-radius: 0 1.5rem 0 0;
        }
      }

      .MuiTableSortLabel-root {
        line-height: inherit;
        .MuiTableSortLabel-icon {
          opacity: 0.75 !important;
          margin: 0 .5rem;
        }
      }

      .MuiTableSortLabel-active {
        color: rgba(0, 0, 0, 0.87);
        .MuiTableSortLabel-icon {
          & > *:first-child {
            fill: #bbbbbb !important;
          }
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableCell-root {
        border-bottom: 1px solid #EBEBEB;
      }
      .MuiTableRow-root {
        height: 4rem;
        &:last-child {
          .MuiTableCell-root {
            border-bottom: none;
          }
        }
      }
    }

    .MuiTableFooter-root {
      display: table-row-group !important;
    }

    .MuiTableCell-root {
      &:first-child {
        padding-left: 2rem;
      }
      &:last-child {
        padding-right: 2rem;
      }
    }
  }
}
