@import 'src/assets/styles/variables';

.tabStepper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  min-height: 4rem;
}

.button {
  margin-top: .55rem !important;
  &.hidden {
    visibility: hidden;
  }
}
