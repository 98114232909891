@import 'src/assets/styles/functions';
@import 'src/assets/styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.iconWrapper {
  margin-right: calculateRem(16px);
  margin-left: calculateRem(16px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.name {
  font-weight: 500;
  font-size: calculateRem(18px);
  line-height: calculateRem(22px);
  color: $color_gray_dark;
  cursor: pointer;
}

.popover {
  margin-top: 30px;
}
