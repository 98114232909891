@import 'src/assets/styles/variables';

%text {
  padding: 5px 10px;
  font-size: .8rem;
  cursor: pointer;
}

.option {
  @extend %text;
  color: $color-gray-dark;
  font-weight: 600;

  &.active {
    background-color: rgba($color-primary, .5);
    color: white;
  }

  &:hover {
    color: $color-primary;
    background-color: rgba($color-primary, .1);
  }
}

.clear {
  @extend %text;
  color: $color-primary;
  &:hover {
    background-color: rgba($color-primary, .1);
  }
}