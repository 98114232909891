.endAdornment {
  margin-right: 0 !important;
}

.underline {
  &:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid rgba(0, 0, 0, 0.42) !important;
  }
  margin-bottom: .5rem;
}
