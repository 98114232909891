@import 'src/assets/styles/variables';
@import 'src/assets/styles/functions';

.link {
  display: inline-block;
  position: relative;
  font-weight: 500;
  font-size: calculateRem(18px);
  line-height: calculateRem(22px);
  color: $link_color_text;
  text-decoration: none;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: transform 250ms ease-out;
}

.link:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: transform 250ms ease-out;
}

.active {
  border: 1px solid #636363;
  border-radius: 8px;
  padding: 12px 16px;
  background-color: #303030;
}
