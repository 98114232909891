@import "src/assets/styles/variables";

.root {
  font-weight: 800 !important;
  font-size: 30px !important;
  line-height: 37px !important;
  color: $color_primary !important;
  border-bottom: 4px solid $color_gray_middle !important;
  border-radius: 2px !important;
  white-space: nowrap !important;
  max-width: none !important;
}

.selected {
  color: $color_secondary !important;
}

.sizeSmall {
  font-size: 18px !important;
  line-height: 22px !important;
}
