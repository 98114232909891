@import "src/assets/styles/variables";

.container {
  display: grid;
  grid-template-areas:
            "nav main main"
            "nav main main"
            "footer footer footer";
  grid-template-columns: $nav_width 1fr 1fr;
  grid-template-rows: auto auto $footer_height;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.main {
  grid-area: main;
  flex-direction: column;
  padding: 40px;
}
