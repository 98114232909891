.creativePreviewWrapper {
  height: 200px;
}

.creativePreviewContainer{
  width: 100%;
  height: 100%;
  max-height: 50vh;
  overflow: auto;
}

.erroeMessage{
  position: relative;
}
