@import "src/assets/styles/variables";

.main {
  width: 100%;
  border: 1px solid $color_gray_dark;
  border-radius: 17px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul {
    width: 70px;
  }
}

.day_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.time_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.timepicker {
  max-width: 100px;
  max-height: 45px;
  div {
    padding-right: 5px;
    margin: 0;
  }

  input {
    padding: 5px 0 5px 8px;
  }
}

.errorMessage {
  width: 100%;
  margin: 0;
  text-align: center;
}
