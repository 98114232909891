@import "src/assets/styles/variables";

.wrapper {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.noWrap {
  white-space: nowrap;
}

.variantLight {
  color: $color_gray;
}

.variantRegular {
  color: $content_text_color;
}

.fontWeightNormal {
  font-weight: 500;
}

.fontWeightBold {
  font-weight: 600;
}
