@import 'src/assets/styles/variables';

.overlayBox {
  /* Overlay entire screen */
  position: fixed;
  z-index: 999;
  inset: 0;
  background: #00000087;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  background: $backgound_color_gray;
  padding: 2em 4em;
  border-radius: 1rem;
}

.buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
