@import 'src/assets/styles/variables';


.menuItem {
  padding-left: 40px !important;
}

.menuItemImage {
  margin-right: 10px;
  width: 40px;
  height: 30px;
  object-fit: cover;
}

.menuItemParent {
  padding-left: 20px !important;
  .menuItemText {
    font-weight: 500 !important;
  }
}

.menuItemParentSingle {
  pointer-events: none;
  cursor: default;
}


.menuItemChecked {
  color: $light_text_color_gray !important;
}

.menuItemText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.chipList {
  margin-top: 1rem;
}

.author {
  margin-top: 5px;
}

.link {
  color: $light_text_color_gray !important;
  &:hover {
    color: $color_accent_light !important;
  }
}
