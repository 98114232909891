:global {
  .globalPreloaderLoading {
    overflow: hidden;
  }
}

.globalPreloader {
  position: fixed;
  top:0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  background-color: rgba(18, 18, 18, 0.5);
  z-index: 10000;
}

.globalPreloaderImage {
  margin: auto;
}
