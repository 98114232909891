@import 'src/assets/styles/variables';

.wrapper {
  max-width: 1160px;
}

.refillButton {
  white-space: nowrap;
}

.download {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $color_primary;
  & > span {
    margin-left: 5px;
  }
}
