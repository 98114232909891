.container {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
}

.imageContainer {
  position: relative;
  width: 70%;
  height: 100%;
  border-right: 1px solid #7f7f7f;
}

.controlsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px;
}

.sliderInput {
  display: flex;
  column-gap: 10px;

  .inputWrapper {
    width: 50px;
    transform: translateY(-18px);
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
   
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

  }

}