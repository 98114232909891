@import "src/assets/styles/variables";

.tooltip {
  background: linear-gradient(180deg, #ffffff 86.13%, #fcfcfc 100%), #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  color: $color_accent;
  font-size: 16px;
  padding: 8px 16px;
}
