@import "src/assets/styles/variables";

@mixin largeScreenQuery {
  @media (max-width: 1200px) { @content; }
}

@mixin _1000_ScreenQuery {
  @media (max-width: 1000px) { @content; }
}


@mixin mediumScreenQuery {
  @media (max-width: 780px) { @content; }
}


@mixin phoneQuery {
  @media (max-width: 430px) { @content; }
}

.main_container {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $background_black;
  font-family: Inter, sans-serif;

  color: $text_primary;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 60px;

  width: 100%;
  max-width: 1270px;
  padding: 0 20px;

  .btn_container {
    display: flex;
    flex-direction: row;
    gap: 24px;

    @include mediumScreenQuery {
      display: none;
    }
  }

  .menu_container {
    display: none;

    @include mediumScreenQuery {
      display: block;
    }
  }

  @include _1000_ScreenQuery {
    margin-top: 60px;
  }

  @include mediumScreenQuery {
    margin-top: 30px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
}

// Text
.text_title {
  font-size: 57px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -0.25px;

  @include phoneQuery {
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0px;
  }
}

.text_title_md {
  margin-left: -5px;

  font-size: 46px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0;

  @include phoneQuery {
    margin-left: 0;

    font-size: 32px;
    line-height: 34px;
  }
}

.text_sub_title {
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -0.25px;

  @include phoneQuery {
    font-size: 32px;
    line-height: 27px;
    letter-spacing: 0;
  }
}

.text_sub_title_2 {
  font-size: 40px;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: -0.25px;

  @include phoneQuery {
    font-size: 14px;
    line-height: 27px;
    letter-spacing: 0;
  }
}

.text_body_md {
  font-size: 36px;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: -0.25px;

  @include phoneQuery {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
  }
}

.text_body {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
}

.text_body_sm {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0px;
}

.text_red {
  color: $main_red;
}

.text_secondary {
  color: $text_secondary;
}

// Buttons

%btn_link {
  padding: 16px 24px;
  padding: 1rem 1.5rem;
  border-radius: 6.25rem;
  color: $text_primary;
  text-decoration: none;

  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;

  max-height: 54px;
}

.btn_full {
  width: 190px;
  display: block;
}

.btn_link_primary {
  @extend %btn_link;
  background-color: $main_red;

  &:hover {
    background-color: $secondary_red
  }
}

.btn_link_ghost {
  @extend %btn_link;
  color: #79C3FE;
  background-color: transparent;

  &:hover {
    background-color: rgba(187,187,187, 0.2)
  }
}

.btn_link_secondary {
  @extend %btn_link;
  background-color: $btn_secondary;

  display: block;

  max-width: fit-content;

  &:hover {
    background-color: rgba(33, 34, 34, 0.6);
  }

  @include phoneQuery {
    min-width: 190px;
    display: block;
  }
}

// Images

.image {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
}

.start_container {
  max-width: 745px;

  @include phoneQuery {
   max-width: 400px;
  }
}

.start_title {
  @extend .text_title;

  margin-top: 3.75rem;
  text-align: center;
}

.start_text {
  @extend .text_body;

  margin-top: 2.5rem;
  text-align: center;
  color: $text_secondary;

  @include mediumScreenQuery {
    margin-right: 20px;
    margin-left: 20px;
  }
}

.start_image {
  @extend .image;

  @include mediumScreenQuery {
    max-width: 430px;
  }

  @include phoneQuery {
    max-width: 295px;
    max-height: 245px;
  }
}

.how_it_works_container {
  max-width: 1130px;
  margin-top: 8.25rem;

  @include mediumScreenQuery {
    margin-top: 7.25rem;
    padding: 0 20px;
  }

  @include phoneQuery {
    margin-top: 6.25rem;
    max-width: 325px;
  }
}

// Cards
.cards_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 1270px;
  max-width: 1270px;
  padding: 0 20px;
  gap: 20px;

  margin-top: 5rem;

  @include largeScreenQuery {
    gap: 40px;
    margin-top: 3.125rem;
    flex-direction: column;
  }
}

.card {
  width: 380px;
  height: 100%;
  background-color: $card_background;
  border-radius: 25px;
  padding: 2rem 1.5rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include phoneQuery {
    width: 330px;
    height: 303px;
    padding: 28px 21px;
    border-radius: 21px;
  }

  .card_text_title {
    font-size: 32px;
    font-weight: 800;
    line-height: 42px;
    letter-spacing: 0px;
    text-align: center;

    margin-top: 1rem;

    @include phoneQuery {
      margin-top: 0.875rem;
      font-size: 28px;
      line-height: 37px;
    }
  }

  .card_text_body {
    @extend .text_body;
    text-align: center;
    color: #898CA9;
    margin-top: 1rem;

    @include phoneQuery {
      margin-top: 0.875rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
    }
  }

  .card_text_link {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;

    color: $main-red;
    margin-top: 2rem;

    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    @include phoneQuery {
      margin-top: 1.75rem;
      font-size: 14px;
      line-height: 14px;
    }
  }
}

// key features section
.features_section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 6.25rem;

  max-width: 1270px;
  padding: 0 20px;

  @include _1000_ScreenQuery {
    flex-direction: column-reverse;
  }

  @include mediumScreenQuery {
    margin-top: 5.375rem;
  }
}

.features_container {

  img {
    height: 100%;
  }

  @include _1000_ScreenQuery {
    margin-top: 4.25rem;
  }

  @include mediumScreenQuery {
    margin-left: 20px;
    margin-right: 20px;
  }

  @include phoneQuery {
    margin-top: 4.25rem;
    max-width: 350px;
  }
}

.features_list {
  padding-left: 1rem;

  >li {
    margin-bottom: 1rem;
    color: $text_secondary;
  }

  .feature_subtitle {
    @extend .text_red;

    font-weight: 700;
  }
}

.features_img_container {
  margin-left: 40px;
  max-width: min-content;

  >div {
    display: flex;
    flex-direction: row;
  }

  @include _1000_ScreenQuery {
    margin-left: 0;
  }

  >:nth-child(1){
    padding-left: 24px;
  }
  >:nth-child(2){
    margin-top: 12px;
  }
}

#f1 {
  @include phoneQuery {
    max-width: 162px;
    max-height: 125px;
  }
}
#f2 {
  @include phoneQuery {
    max-width: 162px;
    max-height: 125px;
  }
}
#f3 {
  @include phoneQuery {
    max-width: 117px;
    max-height: 122px;
  }
}
#f4 {
  @include phoneQuery {
    max-width: 203px;
    max-height: 122px;
  }
}

// red section
.red_section {
  margin-top: 6.25rem;
  width: 100%;
  height: 620px;
  background-color: $main_red;
  position: relative;

  display: flex;
  justify-content: center;
  margin-bottom: 535px;

  @include phoneQuery {
    margin-top: 5rem;
    height: 245px;
    margin-bottom: 265px;
  }
}

.red_container {
  margin-top: 6.25rem;
  max-width: 1060px;
  padding: 0 20px;

  @include largeScreenQuery {
    margin-top: 3.5rem;
    max-width: 750px;
  }


  @include phoneQuery {
    margin-top: 2.375rem;
    max-width: 390px;
  }
}

.red_image {
  position: absolute;
  top: 280px;

  @include largeScreenQuery {
    top: 385px;
    max-width: 750px;
  }

  @include mediumScreenQuery {
    max-width: 430px;
  }

  @include phoneQuery {
    top: 150px;
    max-width: 333px;
  }
}

// brand section
.brand_container{
  >:nth-child(2),
  >:nth-child(3)
  {
    margin-top: 3.5rem;
  }

  @include mediumScreenQuery {
    >:nth-child(1),
    >:nth-child(3) {
      flex-direction: column;
    }
    >:nth-child(2)
    {
      flex-direction: column-reverse;
    }
  }
}

.brand_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  max-width: 1270px;
  gap: 90px;

  ul {
    padding-left: 20px;
    margin-bottom: 0;
  }

  .brand_row_text_container {
  }

  @include _1000_ScreenQuery {
    gap: 20px;

    img {
      max-width: 420px;
    }
  }

  @include mediumScreenQuery {
    img {
      max-width: 460px;
    }
  }

  @include phoneQuery {
    gap: 30px;
    max-width: 340px;

    img {
      max-width: 300px;
    }
  }
}

// grey section
.grey_section {
  margin-top: 10rem;
  padding: 5rem 0;
  background-color: $background_gray;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .grey_container {
    width: 100%;
    max-width: 1270px;
    padding: 0 20px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2.5rem;
  }


  img {
    max-width: 554px;
    max-height: 480px;
    object-fit: contain;

    border-radius: 16px;
  }

  @include largeScreenQuery {
    padding: 5rem 20px;

    gap: 1.75rem;

    img {
      max-width: 415px;
      max-height: 360px;
    }
  }

  @include mediumScreenQuery {
    margin-top: 4rem;
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
    gap: 0;

    img {
      display: none;
    }

    div {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

// featured_on
.featured_container {
  margin-top: 10rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  width: 100%;
  max-width: 1270px;
  padding: 0 20px;

  @include phoneQuery {
    margin-top: 2.75rem;
  }

  .featured_cards_container {
    margin-top: 4.375rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;

    width: 100%;

    @include mediumScreenQuery {
      margin-top: 2.125rem;
      flex-direction: column;
      gap: 38px;
    }
  }

  .featured_card {
    width: 364px;
    height: 158px;
    border-radius: 20px;
    background-color: $background_gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include phoneQuery {
      width: 255px;
      height: 110px;
      border-radius: 14px;
      padding: 26px 15px;

      img {
        max-width: 220px;
        max-height: 90px;
      }
    }

  }
}

//business section
.business_container {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  .business_btn_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.625rem;

    @include phoneQuery {
      flex-direction: column;
    }
  }

  @include phoneQuery {
    margin-top: 6.25rem;
    max-width: 305px;
  }

}

// footer
.footer {
  width: 100%;
  background-color: $background_gray;
  padding: 2.5rem 0 1.5rem  0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 418px;

  margin-top: 10rem;

  @include phoneQuery {
    padding: 2rem 0 1.5rem  0;
    margin-top: 5rem;
    min-height: unset;
  }
}

.footer_column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  max-width: 1270px;
  padding: 0 20px;

  @include phoneQuery {
    flex-direction: column;
    gap: 38px;
    margin-bottom: 60px;
  }
}

.footer_last_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 1270px;
  padding: 0 20px;

  @include mediumScreenQuery {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
}

.footer_policies {
  display: flex;
  flex-direction: row;
  align-items: center;


  gap: 32px;
}

.footer_link {
  color: $text_primary;

  @include phoneQuery {
    font-size: 13px;
    line-height: 19px;
  }
}

.footer_socials {
  @include phoneQuery {
    display: none;
  }
}

// Other
.flex_row_center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex_col_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex_row_between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
