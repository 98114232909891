@import "src/assets/styles/variables";


.root {
  min-height: 4rem;
  padding: 0 !important;
  margin: 2rem !important;
  border-radius: 1rem !important;
  border: 1px solid $color_gray_middle;
  display: flex;
  justify-content: center;

  li > button[aria-current=true] {
    background-color: transparent !important;
    color: $color_primary;
  }

  & :global {
    button {
      font-size: 1rem;
      color: #BBBBBB;
      font-weight: 500;
      &[class*="PaginationItem-selected"] {
        color: $color_primary;
        font-weight: 600;
        background-color: transparent;
      }
    }

    li:first-child button,
    li:last-child button {
      width: 100%;
      padding: 0 .5rem;
      border-radius: .5rem;
    }

    li:first-child {
      margin-right: auto;
      margin-left: 2rem;
    }

    li:last-child {
      margin-left: auto;
      margin-right: 2rem;
    }
  }

}
