.wrapper {
  position: relative;
}

.preloaderWrapper {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.isLoading {
  opacity: .3;
}
