@import 'src/assets/styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
}

.filename {
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 1rem;
  .label {
    color: $color_gray;
    margin-right: 1rem;
  }
}

.button {
  font-size: 1rem;
  padding: 6px 8px;
}

.buttonLabel {
  white-space: nowrap;
}

.title{
  font-size: 0.875rem;
  line-height: 1.0625rem;
}
