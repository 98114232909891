@import 'src/assets/styles/variables';
@import 'src/assets/styles/functions';

.link {
  font-size: calculateRem(16px);
  font-weight: 600;
  color: $color_accent;
  text-decoration: none;

  &:hover {
    color: $color_accent_light;
  }
}
