@import 'src/assets/styles/functions';
@import "src/assets/styles/variables";

.fieldWrapper {
  margin-bottom: calculateRem(8px);

  >div {
    margin-bottom: calculateRem(20px);
  }
}


.inputClass {
  width: 100% !important;
  background-color: transparent !important;
  font-size: 1.125rem !important;

  &:focus,
  &:active {
    border-color: $light_text_color_gray !important;
    box-shadow: none !important;
    border-width: 1px !important;
  }

  &:hover {
    border-color: inherit !important;
  }
}

