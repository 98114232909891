@import 'src/assets/styles/variables';

.wrapper {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: $light_text_color_gray;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.label {
  margin-right: .5rem;
}

.value {
  color: $content_text_color;
}
