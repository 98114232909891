@import "src/assets/styles/variables";
@import "src/assets/styles/functions";

.stepContainer {
  padding: 0;
  margin-bottom: 0;
}


.container {
  width: 100%;
  background: $backgound_color_gray;
  box-sizing: border-box;
  border-radius: 0.375rem;
  margin-bottom: 1.875rem;
  padding-top: 1.5rem;
}

.wrapperTitle {
  overflow: hidden;
}

.validForm {
  color: $color_secondary !important;
  border: 1px solid $color_secondary !important;
}

.customError {
  display: flex;
  color: #fff;
  padding: 6px 16px 6px 20px;
  font-size: 0.875rem;
  font-family: Public Sans, sans-serif;
  font-weight: 400;
  line-height: 1.46429em;
  border-radius: 4px;
  flex-grow: initial;
  min-width: 288px;
  background-color: #d32f2f;

  .link {
    text-decoration: none;
    color: yellowgreen;
  }
}

.statusBadge {
  margin-top: 10px !important;
}

.declined {
  font-size: 1.175rem;
  line-height: 2.25rem;
  color: #d32f2f;
}

.terms{
  color: $color_gray_dark;
}
