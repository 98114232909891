@import 'src/assets/styles/variables';
@import 'src/assets/styles/functions';

.step {
  text-align: left;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: auto;
  padding: 2rem;
}

.step_buttons {
  width: 100%;
  padding: 0 2rem;
}

.bar {
  background: $backgound_color_gray;
  border-bottom: 1px solid $color_gray_dark;
  padding-bottom: 1rem;

  span {
    text-transform: none !important;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  .hidden {
    display: none;
  }
}

.active {
  color: $color_secondary !important;
}

.valid {
  color: $color_secondary !important;
  opacity: 1 !important;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .icon {
    color: $button_color_gray;
    width: calculateRem(50px);
    height: calculateRem(50px);
  }

  .text {
    font-size: calculateRem(18px);
    font-weight: 600;
    position: absolute;
    left: 20px;
    top: 15px;
    color: $color_primary;
  }
}
