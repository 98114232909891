.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.values {
  display: inline-block;
  & > div:not(:last-child) {
    margin-right: 1rem;
  }
}