@import "variables";

html, body {
  overflow: hidden;
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

#root {
  height: 100vh;
  width: 100vw;

  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 30vw;
    height: 30vw;
    border-radius: 50%;
    z-index: -1;
    opacity: 0.8;
    filter: blur(50px);
  }

  &::after{
    background: linear-gradient(45deg, #f9423a 50%, #00f5a0 100%);
    top: -10vw;
    right: -10vw;
  }
  &::before {
    background: linear-gradient(-135deg, #f9423a 50%, #00f5a0 100%);
    bottom: -15vw;
    left: -15vw;
  }

  @media (max-width: 780px) {
    &::after,
    &::before {
      display: none;
    }
  }
}

.link { text-decoration: none; }
