@import 'assets/styles/variables';

.preview {
  height: 300px;
  border: none;
}

.title{
  position: relative;
  z-index: 10;
  background-color: $color_gray_lighter;
}

.titleText {
  padding: .2rem 0 0 0;
}

.section {
  width: 100%;
}

.container{
  width: 100%;
  height: 100%;
  max-height: 50vh;
  overflow: auto;
}
