@import 'src/assets/styles/variables';
@import 'src/assets/styles/functions';
.wrapper {
  position: relative;
}

.link {
  color: $color_gray;
}

.logo {
  width: 120px;
  height: 52px;
}
