@import 'functions';
@import 'variables';

.subtitle-text {
  font-weight: 500;
  font-size: calculateRem(14px);
  line-height: calculateRem(19px);
  color: $color_gray;
}

.grayContainer {
  width: 100%;
  background: $backgound_color_gray !important;
  border-radius: 0.375rem;
  padding: 2.5rem;
  margin-bottom: 1.875rem;
}

.pageTitle {
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-top: 0px;
  margin-bottom: 16px;
}

.tab-stepper {
  min-height: 4rem;
  padding: 0 !important;
  border-radius: 1rem !important;
  border: 1px solid $color_gray_middle;
  display: flex;

  button {
    font-size: 1rem;
    color: $color_text;
    border-radius: calculateRem(11px);
    margin-top: .55rem;
    height: calculateRem(44px);
  }
}

.selected {
  background: $color_primary;
}

.selectedDisabled {
  background: $color_gray;
}

.disabled {
  color: $color_gray !important;
}

.disabledIcon {
  path { fill: $color_gray !important; }
}

.d-none {
  display: none !important;
}

.relative {
  position: relative !important;
}

/*-------------------- Scrollbar --------------------*/
/* width */
::-webkit-scrollbar {
  background-color: $background_black;
  border-radius: 1rem;
  width: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: $background_black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $color_secondary;
  border-radius: 1rem;
  border: 4px solid $background_black;
  &:horizontal {
    background-color: $color_secondary;
  }
  &:vertical {
    background-color: $color_secondary;
  }
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: $color_secondary;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display:none
}


/*-------------------- Buttons --------------------*/
.buttonLink {
  color: inherit !important;
  text-decoration: unset;
}

.movingBySvg {
  cursor: move;
  & > * {
    cursor: move;
    pointer-events: none;
  }
}
