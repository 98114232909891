@import "src/assets/styles/variables";
@import "src/assets/styles/functions";

.wrapper {
  display: inline-block;
}

.wrapperFullWidth {
  width: 100%;
  & :global {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  >p {
    margin-left: 14px;
    margin-right: 14px;
  }
}

.calendar {
  z-index: 20;

  :global {
    .react-datepicker {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: none;
      padding: 20px;
      background-color: $backgound_color_gray;
      color: $content_text_color;
      border-radius: 16px;
      box-shadow: 0 10px 50px rgba(0, 0, 0, 0.12);
    }

    .react-datepicker__header {
      padding: 0 5px;
      background: transparent;
      border-bottom: none;
    }

    .react-datepicker__day {
      color: $content_text_color;
      height: 50px;
      width: 50px;
      font-size: 18px;
      line-height: 22px;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      border-radius: 10%;
      &:hover {
        color: $backgound_color_gray;
        border-radius: 10%;
        background-color: #8a83da;
      }
      &-names {
        display: none;
      }
      &--outside-month {
        color: $color_gray;
      }
      &--today {
        border: 1px solid $color_gray_light ;
        font-weight: 400;
      }
      &--keyboard-selected {
        background-color: #a899ff;
      }
      &--selected {
        background-color: #6257d1;
      }
      &--disabled {
        color: $color_gray_dark;
      }
    }
    .react-datepicker__children-container {
      width: 100%;
    }

  }
}

.buttonArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  &:hover {
    background: $color_gray;
  }
}

.calendarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendarFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 20px;
}
