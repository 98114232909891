.navItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
