@import "src/assets/styles/variables";
@import 'src/assets/styles/functions';

.wrapper {
  grid-area: nav;
  position: sticky;
  top: 0;
  width: $nav_width;
  margin-top: 0;
  background-color: rgba(27, 27, 27, 0.5);
  backdrop-filter: blur(15px);
  height: 100%;
  max-height: 100vh;
  z-index: 5;
}

.container {
    height: 100%;
    padding-top: 46px;
    padding-left: 42px;
}

.logo {
  margin-bottom: 40px;
  svg {
    width: 110px;
    height: 50px;
  }
}
