@import "src/assets/styles/variables";


.indicator {
  display: flex;
  justify-content: center;
  border-radius: 15px;
  height: 4px !important;
  background-color: $color_secondary !important;
}
