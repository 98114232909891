@import 'src/assets/styles/variables.scss';
@import 'src/assets/styles/functions.scss';

@keyframes blackout {
  from {
    background: rgba(18, 18, 18, 0);
  }
  to {
    background: rgba(18, 18, 18, 0.5);
  }
}

@keyframes slideRight {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}

.container {
  padding-left: 0 !important;
  padding-right: 0 !important;

  height: 100%;
  min-height: 100vh;
  position: relative;
  overflow: auto;

  background: #fff;
  color: $whiteThemeTextColor;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: $whiteThemeTextColor !important;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: none;
  }

  [class*="FormikCheckbox"] {
    svg{
      color: $whiteThemeTextColor !important;
    }
  }


  [class*="MuiFormControl-root"] {
    [class*="MuiInputBase-root"]{
      color: $whiteThemeTextColor !important;

      fieldset {
        border-color: $color_gray_middle !important;
      }
    }

    [class*="Mui-focused"] {
      fieldset {
        border-color: $color_gray_dark !important;
      }
    }

    [class*="Mui-error"] {
      fieldset {
        border-color: $color_error !important;
      }
    }

    svg {
      color: $whiteThemeTextColor;
    }
  }
}

.leftCol {
  overflow: hidden;
  max-height: 100%;
  position: relative;
  padding: 3rem 4rem;
  background: #fff;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rightCol {
  padding: 3rem 4rem;
  padding-bottom: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  font-size: calculateRem(26px);
  line-height: calculateRem(36px);
  font-weight: 600;
  margin-bottom: 1rem !important;
}

.subtitle {
  margin-bottom: 2rem !important;
}

.inputName {
  margin-bottom: 4px;
}

@media screen and (min-width: 600px) {
  .leftCol {
    min-width: 460px;
  }
}

.logo {
  margin-bottom: 2rem;
}

.marginBottom {
  margin-bottom: 1.5rem;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.select{
  margin-bottom: 0.5rem;

  span{
    color: $whiteThemeTextColor !important;
  }
}

.footer {
  margin-top: 2rem;

  .copyright{
    font-size: calculateRem(14px);
    font-weight: 600;
  }

  .link {
    font-size: calculateRem(14px);
    font-weight: 400;
    color: $color_accent;
    text-decoration: none;

    &:hover {
      color: $color_accent_light;
    }
  }
}