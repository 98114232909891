.mainContainer {
  width: 100%;
  height: 100vh;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  overflow: auto;

  h1 {
    color: white;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
}

.effectiveDate {
  margin-bottom: 2rem;
  font-weight: 700;
}

.listLetters {
  list-style-type: lower-alpha;
  padding-bottom: 0;
}

.listDisc {
  list-style-type: disc;
  padding-bottom: 0;
}

.listCircle {
  list-style-type: circle;
  padding-bottom: 0;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}
