@import 'src/assets/styles/functions';
@import 'src/assets/styles/variables';

.wrapper {
  width: 100%;
}

.welcomeTitle {
  margin-top: 0px;
  margin-bottom: 55px;
  font-size: 2.2rem;
}

.name {
  text-transform: capitalize;
}

.cardsWrapper {
  margin-bottom: 40px;
}

.chartsWrapper {
  width: 100%;
  background: $backgound_color_gray !important;
  padding: 2.5rem;
  margin-bottom: 40px;
  border-radius: calculateRem(6px);
}

.chart {
  margin: 0 auto;
  font-size: 0.8rem !important;
  line-height: 1rem !important;
}

.iconRefresh {
  fill: $color_gray;
  cursor: pointer;
  &:hover {
    fill: $color_gray_dark;
  }
}
