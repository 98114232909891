.searchWrapper {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  margin: .45rem .45rem .45rem auto;
  border-radius: unset !important;
  min-width: 15rem;
  background-color: transparent;
}

.searchIconButton {
  padding: 5px !important;
}

.searchInput {
  flex: 1;
}