@import 'functions';

$footer_height: 5rem;
$nav_width: 15rem;

$color_accent: #7367F0;
$color_accent_light: #a67bb0;

$purpleButton: #393b63;
$purpleButtonLight: #4f5189;

// landing colors
$background_black: #121212;
$background_gray: #313033;
$card_background: #1a1b23;
$btn_secondary: #212222;
$main_red: #F9423A;
$secondary_red: #ff503b;
$text_primary: #F7FBFC;
$text_secondary: #C8CCCC;

$color_text: #36314c;
$color_gray_dark: #888888;
$color_gray_darkest: #2f2f2f;
$color_gray: #bbbbbb;
$color_gray_middle: #dedede;
$color_gray_light: #ebebeb;
$color_gray_lighter: #f9f9f9;

$color_light_blue: #85D5FF;

$color_error: #f44336;
$link_color_text: #F9F9F9;

//shades of blue
$royalBlue: #005885;
$darkBlue: #0079B8;
$blue: #009BEB;
$cornflowerBlue: #1FB3FF;
$paleCornflowerBlue: #52C4FF;
$lightBlue: #85D5FF;

//New coloros for black theme
$color_primary: #F9F9F9;
$color_secondary: #F9423A;
$color_green: #B7D317;

$light_text_color_gray: #8692D0;
$dark_text_color_gray: #7983bb;
$content_text_color: #CFE3DC;

$backgound_color_gray: #2F3349;
$button_color_gray: #363B54;

$color_error_secondary: #F44336;

$whiteThemeTextColor: #4B465C;

$dashboardBackgroundColor: #303030;

:export {
  primary: $color_primary;
  gray: $color_gray;
  royalBlue: $royalBlue;
  darkBlue: $darkBlue;
  blue: $blue;
  cornflowerBlue: $cornflowerBlue;
  paleCornflowerBlue: $paleCornflowerBlue;
  lightBlue: $lightBlue;
}
