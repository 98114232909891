.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons {
  display: flex;
  align-items: center;
}

.title {
  max-width: 40%;
  width: 40%;
  overflow: hidden;
}