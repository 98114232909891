@import "src/assets/styles/variables";

.root {
  color: $color_primary !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  text-transform: none !important;
  min-width: 100px !important;
}

.rootAddCampaign {
  color: $color_primary !important;
  border-bottom: 4px solid $color_gray_light !important;
  min-width: unset !important;
  flex-grow: 1;
  max-width: 100%;
}

.labelIcon,
.labelIconAddCampaign {
  min-height: 48px !important;
  padding-top: 5px !important;
}

.icon {
  position: absolute;
  right: 10px;
  top: 8px;
  margin-bottom: 0!important;
}
