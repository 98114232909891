@import 'src/assets/styles/variables';

.catWrapper {
  width: 100%;
  text-align: center;
}

.targeting {
  display: flex;
  flex-direction: column;
  height: 100%;

  .checkboxWrapper {
    display: flex;
    align-items: center;
  }

  .helpIcon {
    position: relative;
    top: -3px;
    cursor: pointer;
  }
}

.zipCode {
  flex-grow: 1;
  margin-right: 1rem;
}

.uploadButton {
  height: 35px;
}
