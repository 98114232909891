@import 'src/assets/styles/functions';
@import "src/assets/styles/variables";

.preview {
  width: 100%;
  height: 100%;
  border: 2px solid $color_gray_dark;
}

.videoCreative {
  object-fit: fill;
}

.imageCreative {
  object-fit: contain;
}

.customNativePreviewWrapper {
  width: 100%;
  height: 100%;
  border: none;
}

.collapsablePreviewWrapper {
  width: 100%;
  height: 100%;
  background-position: center bottom;
  background-size: cover;
}

.collapsablePreview {
  bottom: 0;
  height: 25%;
  width: 100%;
}

.placeholder {
  background: #dddddd;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    height: 100%;
    width: 100%;
    z-index: 1;
    @include animate(null, linear, 1s, infinite, shimmer);
  }
}

@include keyframes(shimmer) {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
