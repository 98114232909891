@import "src/assets/styles/variables";
@import "src/assets/styles/functions";

.title {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.titleText {
  display: inline-block;
  padding: .2rem 0 0 0;
}

.subtitleText {
  font-weight: 500 !important;
  font-size: calculateRem(12px) !important;
  line-height: calculateRem(15px) !important;
  color: $color_gray !important;
  padding: .25rem .75rem;
}
