@import 'src/assets/styles/variables';

.subtitle {
  font-weight: 500;
  font-size: calculateRem(14px);
  line-height: calculateRem(19px);
  color: $color_gray;
}

.container {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1.875rem;
}

.title {
  margin-bottom: 0.75rem !important;
}
