@import 'src/assets/styles/variables';

.cellFirstRow,
.cellSecondRow {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $content_text_color;
}

.cellFirstRow{
  color: $content_text_color;
}

.cellSecondRow {
  color: $content_text_color;
  margin-top: 8px;
}
