@function calculateRem($size) {
  $base_font-size: 16px;
  $remSize: $size / $base_font-size;
  @return #{$remSize}rem;
}

@mixin animate($delay, $direction, $duration, $iteration-count, $name){
  animation: $name $delay $direction $duration $iteration-count 
}

@mixin keyframes($name){
  @keyframes #{$name}{
      @content;
  }
}