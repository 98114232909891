@import "src/assets/styles/variables";

.wrapper {
  grid-area: footer;
  width: 100%;
  z-index: 10;
  height: $footer_height;
  line-height: 5rem;
  background-color: rgba(27, 27, 27, 0.5);
  backdrop-filter: blur(15px);
  margin-top: auto;
}

.copy,
.version {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #f9f9f9;
  opacity: 0.5;
}

.link {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff !important;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.version {
  margin-right: 20px;
}
